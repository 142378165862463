// ANCHOR React
import React from 'react';

// ANCHOR Components
import { LyonPage } from '@lyon/components/LyonPage';
import { Lyon404Container } from './Lyon404Container';

export const Lyon404 = React.memo(() => (
  <LyonPage hideSidebar>
    <Lyon404Container />
  </LyonPage>
));
