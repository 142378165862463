import { BlockOverrides } from 'baseui/block';

export const BLOCK: BlockOverrides = {
  Block: {
    style: {
      marginTop: '8px',
      marginBottom: 0,
    },
  },
};
