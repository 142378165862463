// ANCHOR React
import React, { memo } from 'react';

// ANCHOR Base
import { styled } from 'baseui';

const Image404 = styled('img', {
  width: '100%',
  height: '100%',
  objectFit: 'contain',
});

export const Lyon404Image = memo(() => (
  <Image404
    src="https://ucarecdn.com/1c2d0bfe-4ad1-4762-a4d0-fadb62505292/404.svg"
    alt="Not found"
  />
));
