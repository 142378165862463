import { BlockOverrides } from 'baseui/block';

export const BLOCK: BlockOverrides = {
  Block: {
    style: {
      width: '100%',
      height: '55%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
};
