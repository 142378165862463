// ANCHOR React
import { memo } from 'react';

// ANCHOR Base
import { ParagraphXSmall } from 'baseui/typography';

// ANCHOR Styles
import { BLOCK } from './styles';

export const Lyon404Description = memo(() => (
  <ParagraphXSmall overrides={BLOCK}>The page you are looking for does not exist.</ParagraphXSmall>
));
