// ANCHOR React
import React, { memo } from 'react';

// ANCHOR Base
import { Block } from 'baseui/block';

// ANCHOR Components
import { Lyon404Image } from './Lyon404Image';

// ANCHOR Styles
import { BLOCK } from './styles';

export const Lyon404ImageContainer = memo(() => (
  <Block overrides={BLOCK}>
    <Lyon404Image />
  </Block>
));
