// ANCHOR React
import * as React from 'react';

// ANCHOR Base
import { HeadingXSmall } from 'baseui/typography';

// ANCHOR Styles
import { BLOCK } from './styles';

export const Lyon404Title = React.memo(() => (
  <HeadingXSmall overrides={BLOCK}>Page not found</HeadingXSmall>
));
