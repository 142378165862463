// ANCHOR React
import * as React from 'react';

// ANCHOR Base
import { Block } from 'baseui/block';

// ANCHOR Components
import { Lyon404Title } from './Lyon404Title';
import { Lyon404Description } from './Lyon404Description';

// ANCHOR Styles
import { BLOCK } from './styles';

export const Lyon404InfoContainer = React.memo(() => (
  <Block overrides={BLOCK}>
    <Lyon404Title />
    <Lyon404Description />
  </Block>
));
