// ANCHOR React
import React from 'react';

// ANCHOR Base
import { Block } from 'baseui/block';

// ANCHOR Components
import { Lyon404ImageContainer } from './Lyon404ImageContainer';
import { Lyon404InfoContainer } from './Lyon404InfoContainer';

// ANCHOR Styles
import { BLOCK } from './styles';

export const Lyon404Container = React.memo(() => (
  <Block overrides={BLOCK}>
    <Lyon404ImageContainer />
    <Lyon404InfoContainer />
  </Block>
));
